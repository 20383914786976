<template>
  <div class="container mt-5">
    <div class="row">
      <div class="col text-center text-nowrap">
        <div class="d-flex justify-content-around">
          <div class="col-4">
            <a v-if="this.monthDiffDefault != this.monthDiff" v-on:click="monthsAgo()" class="text-primary" style="cursor:pointer">＜前の月へ</a>
            <a v-else class="text-black-50">&nbsp;</a>
          </div>
          <div class="col-4">
          <a v-on:click="thisMonth()" v-if="this.monthDiffDefault != this.monthDiff" class="text-primary" style="cursor:pointer">今月へ</a>
          <a v-else class="text-black-50">&nbsp;</a>
          </div>
          <div class="col-4">
            <a v-on:click="monthsLater()" v-if="this.ym != '2025/3'" class="text-primary" style="cursor:pointer">次の月へ＞</a>
            <a v-else class="text-black-50">&nbsp;</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <form  method="post" id="reservation-calendar">
    <input type="hidden" name="step" value="step2">
    <div class="reservation-calendar">
      <div class="scheduleMonthly scheduleMonthlyThisMonth slideBox2">
        <div id="visual" class="mb-3">
          <ul class="slick_visual2 slick-initialized slick-slider">
            <div class="slick-track">
              <div aria-live="polite" class="slick-list">
                <div role="listbox" style="opacity: 1; width: 1386px;">
                  <li class="slick-slide" data-slick-index="0" aria-hidden="true" tabindex="-1" role="option" aria-describedby="slick-slide10">
                    <p class="scheduleMonthlyPTitle font-family-jun-pro">{{this.year}}年{{this.month}}月</p>
                    <table id="calender_e">
                      <tbody>
                      <tr>
                        <th v-for="item in makeWeek()" :key="item.key" :class="item.key">{{item.value}}</th>
                      </tr>
                      <tr v-for="weeks in fetchCalendarData()" :key="weeks.key">
                        <td v-for="week in weeks" :key="week.key" :class="week.classes" @click="checkReserveDay(week.classes) ? postReserveDay(week.day) : ''">
                          <template  v-if="week.day != '00'">
                            {{week.day}}
                            <span class="d-block text-center font-weight-bold" v-if="week.capacity > 0">〇</span>
                            <span class="d-block text-center" v-else>×</span>
                            <span class="sr-only"></span>
                          </template>
                          <template  v-else>
                            <span class="d-block text-center"></span>
                            <span class="sr-only"></span>
                          </template>
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </li>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import axios from 'axios';
import moment from 'moment';

export default {
  name: 'reservation-calendar',
  props: {},
  data()
  {
    return {
      api_url : 'api/calendar/calendar.json',
      post_url: 'checkup-reserve',
      condition : {
        year : null,
        month : null,
        day : null,
        week : [],
        holiday : 0,
        addDays : 0,
      },
      // date : new Date().setMonth(new Date().getMonth() + 11),
      date : new Date(),
      fiscalYear : null,
      year : null,
      month : null,
      monthDiffDefault: 0,
      monthDiff: 0,
      holidays : {},
      calendarData : {},
      times : [],
      startTime : null,
      endTime : null,
      results : null,
      ym : null,
    }
  },
  created()
  {
    moment.locale('ja');
  },
  mounted()
  {
    let now = new Date();
    now.setMonth(4);

    if((now.getMonth()+1) > 3){
      now.setFullYear(new Date().getFullYear() + 1);
    }

    this.fiscalYear = now;

    this.monthDiff = this.setDiffMonth();
    this.monthDiffDefault = this.monthDiff;

  },
  methods : {
    /**
     * カレンダーデータ取得
     */
    fetchCalendarData()
    {
      this.year = new Date(this.date).getFullYear();
      this.month = new Date(this.date).getMonth()+1;
      this.ym = this.year + '/' + this.month;

      axios.get(this.api_url,{
        params : {
          year :this.year,
          month :this.month,
        }
        })
          .then(response => {
            this.calendarData = response.data;
          })
          .catch(error => {
            alert('祝日データの取得に失敗しました');
            console.log(error)
          });
      return JSON.parse(this.calendarData);
    },
    /**
     * 前の月を取得
     */
    monthsAgo()
    {
      this.date = new Date(this.date).setMonth(new Date(this.date).getMonth() - 1);
      this.fetchCalendarData();
      this.monthDiff = this.setDiffMonth();
    },
    /**
     * 次の月を取得
     */
    monthsLater()
    {
      this.date = new Date(this.date).setMonth(new Date(this.date).getMonth() + 1);
      this.fetchCalendarData();
      this.monthDiff = this.setDiffMonth();
    },
    /**
     * 今月を取得
     */
    thisMonth()
    {
      this.date = new Date();
      this.fetchCalendarData();
      this.monthDiff = this.monthDiffDefault;
    },

    /**
     * 月数の差分を更新
     */
    setDiffMonth()
    {
      let d1Month, d2Month;
      d1Month = this.fiscalYear.getFullYear() * 12 + this.fiscalYear.getMonth();
      d2Month = new Date(this.date).getFullYear() * 12 + new Date(this.date).getMonth();
      return d1Month - d2Month;
    },

    /**
     * 曜日生成
     * @returns {*[]}
     */
    makeWeek()
    {
      let result = [];
      let week = [
          '日', '月', '火', '水', '木', '金', '土'
      ]
      let className = '';
      for(let w=0; w < 7; w++)
      {
        // 曜日に応じてクラス名を変更
        if(w == 0){
          className = "scheduleMonthlySun";
        }else if(w == 6){
          className = "scheduleMonthlySat";
        }else{
          className = "scheduleMonthlyDay";
        }
        result.push({
          key : className,
          value : week[w]
        });
      }
      return result;
    },

    /**
     * 予約日を送信
     * @returns {*[]}
     */
    postReserveDay(day)
    {
      //formオブジェクトを取得する
      let fm = document.getElementById("reservation-calendar");

      //input要素を生成
      const input = document.createElement('input');

      //input要素にtype属性とvalue属性を設定
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', 'reserve-day');
      input.setAttribute('value', this.year+"/"+ this.month + "/" + day);

      //form要素の末尾に挿入
      fm.appendChild(input);


      //Submit形式指定する（post/get）
      fm.method = "post";  // 例）POSTに指定する
      //action先を指定する
      fm.action = "/"+this.post_url;  // 例）"/php/sample.php"に指定する
      //Submit実行
      fm.submit();

    },

    /**
     * 予約可能日かをcheckする
     * @returns 可能日ならtrue
     */
    checkReserveDay(classes)
    {
      return classes.includes('reserve-available');
    },

  },
}
</script>

<style>
</style>
